import { ReactElement, useState } from 'react';
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { useTranslation } from 'react-i18next';

interface DeleteConfirmProps {
  title: string;
  text: string;
  buttonText?: string
  onConfirm: () => void;
  additionnalButton?: ReactElement;
  isDeletable?: boolean;
  checkDependency?: (questionId: number) => Promise<boolean>;
  questionId?: number;
}

const titleId = 'action-confirm-title';
const descriptionId = 'action-confirm-description';

const DeleteConfirm = ({ title, text, onConfirm, buttonText = '', additionnalButton, questionId, isDeletable = true, checkDependency = undefined }: DeleteConfirmProps): ReactElement => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);
  const cantDeleteText = t('cant_delete_text');
  const handleConfirm = (): void => {
    setOpen(false);
    onConfirm();
  };
  const handleClick = (): void => {
    if (checkDependency) {
      checkDependency(questionId);
    }
    setOpen(true);
  };

  return (
    <>
      <ButtonGroup>
        {additionnalButton}
        {
          buttonText ? (
            <Button
              aria-label="action"
              type="button"
              variant="text"
              className="bg-red-delete text-white hover:bg-red-delete hover:opacity-90 px-6"
              onClick={(): void => setOpen(true)}
              startIcon={(
                <DeleteOutlineIcon
                  fontSize="small"
                  color="inherit"
                />
              )}
            >
              {buttonText}
            </Button>
          ) : (
            <Button
              onClick={handleClick}
              className="bg-red-delete text-white hover:bg-red-delete hover:opacity-90 px-6"
              type="button"
              variant="text"
            >
              <DeleteOutlineIcon
                fontSize="small"
                color="inherit"
              />
            </Button>
          )
        }
      </ButtonGroup>
      <Dialog
        open={open}
        onClose={(): void => setOpen(false)}
        aria-labelledby={titleId}
        aria-describedby={descriptionId}
        PaperProps={{ style: { backgroundColor: 'white' } }}
      >
        <DialogTitle id={titleId} className="text-primary">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id={descriptionId} className="text-dark">
            {isDeletable ? text : cantDeleteText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(): void => setOpen(false)}
            color="primary"
            variant="outlined"
          >
            {t('common_cancel')}
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            variant="contained"
            sx={{ display: isDeletable ? '' : 'none' }}
          >
            {t('common_ok')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteConfirm;
